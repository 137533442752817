@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: 1000px;
}

.App {
  /* display: flex; */
  /* flex-direction: row-reverse; */
}

/* .banner {
  width: 100%;
  height: 100%;
  background-image: url("./images/screens.png");
  background-size: contain;
  background-repeat: no-repeat;
} */

section {
  height: 100%;
  text-align: center;
  padding: 30px 0;
  /* height: 80vh; */
}

.home {
  text-align: start;
  padding-top: 30px;
}

.icon-row {
  justify-content: flex-start !important;
  margin-top: 30px;
}

.icon-row img {
  width: 35px;
  margin-left: 15px;
}

.icon-row h1 {
  font-size: 26px;
  color: #7a56b4;
  font-family: Pacifico;
  font-weight: normal;
  margin: 0;
}

.home h1 {
  font-size: 35px;

}

.content-wrapper {
  padding: 20px 30px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.screenshot {
  /* width: 100%; */
  /* max-width: 350px; */
  height: 600px;
  /* background-image: url("./images/screens.png"); */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
}

.subtext {
  font-size: 14px;
  color: #777;
}

.main-cta {
  background-color: black;
  padding: 10px 40px;
  color: white;
  display: block;
  border: none;
  margin: 20px 0;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.main-cta:hover,
.main-cta:active {
  opacity: 0.9;
}

.bonuses {
  margin-top: 50px;
}

.pricing-wrapper {
  list-style: none;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  padding: 30px 0 10px;
}

.pricing-item {
  flex: 1;
  padding: 30px;
  border-radius: 10px;
  height: auto;
  /* box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.1); */
  max-width: 29%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pricing-item img {
  width: 50px;
}

.pricing-item p {
  flex: 1;
}

.pricing-item .price {
  align-self: flex-end;
  width: 100%;
}

.pricing-item span {
  display: block;
}

.pricing-item del {
  color: #777;
  font-size: 12px;
}

.pricing-item b {
  display: block;
}

.price-bold {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.savings {
  width: 70%;
  margin: 0 auto;
  background: #ececec;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.final-price {
  text-align: center;
  width: 100%;
  padding: 0;
}

.final-price h1 {
  text-align: center;
  font-size: 24px;
}

.final-price del {
  color: #777;
}

.large-button {
  width: 40%;
  font-size: 20px;
  margin: 50px auto 20px;
}

footer {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 620px) {
  body {
    text-align: center;
  }

  .row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-cta {
    margin: 30px auto;
  }

  .home {
    display: block;
    text-align: center;
  }

  .pricing-item { 
    max-width: 80%;
    width: 80%;
    margin: 20px auto;
    box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.1);
  }
}
